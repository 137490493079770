import * as React from "react";
import { Container } from "react-bootstrap";
import { WmkSeo } from "wmk-seo";

const NotFoundPage = () => (
  <Container>
    <WmkSeo.Meta
      title="404: Not found"
      slug="404"
      siteTitle="DIPRA"
      baseUrl="https://dipra.org"
    />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist.</p>
  </Container>
);

export default NotFoundPage;
